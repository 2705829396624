<template>
  <section class="m-setting-identity m-scroll">

    <!-- 头部导航 -->
    <m-header title="身份认证">
      <span slot="right" @click="onNext">下一步</span>
    </m-header>

    <p class="title">请完成以下身份认证</p>

    <div class="input-cell" v-show="toggle">
      <input v-model="phone" type="text" readonly>
    </div>

    <div class="input-cell" v-show="!toggle">
      <input v-model="email" type="text" readonly>
    </div>

    <div class="input-cell">
      <input v-model="code" type="text" :placeholder="placeholder">
      <div class="get-code">
        <span v-show="!getCode" @click="onSend" class="get">获取验证码</span>
        <span v-show="getCode"  class="seconds">{{seconds}}S</span>
      </div>
    </div>
  
    <div class="footer">
      <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">下一步</van-button>
    </div>

    <p class="for-email" @click="onToggle()" v-show="toggle && all">邮箱验证</p>
    <p class="for-email" @click="onToggle()" v-show="!toggle && all">手机号验证 </p>





    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { phone_code, phone_code_check, email_code, email_code_check } from '@/api/zh/login'


export default {
  name:'IdentityCheck',
  components:{ MHeader },
  data(){
    return {
      toggle: true,
      all: true,
      phone:'',
      email:'',
      code:'',
      placeholder:'请输入手机验证码',
      getCode:false,
      seconds:60,
    }
  },

  

  methods:{

    
    

    onToggle(){
      this.toggle = !this.toggle
      this.getCode = false
      this.seconds = 60
      this.placeholder = this.toggle ? '请输入手机验证码' : '请输入邮箱验证码'
    },

    // 发送手机验证码
    sendPhoneCode() {
      let params = { 
        phone: this.phone,
        area_code: 0,
        model: 'repass'
      }
      this.$store.commit('load')
      phone_code(params).then(res => {
        if(res) {
          this.getCode = true
          const timer = setInterval( () => {
            this.seconds --
            if(this.seconds < 0){
              this.getCode = false
              this.seconds = 60
              clearInterval(timer)
            }
          },1000)
        }
      }).finally ( () => {
        this.$store.commit('unload')
      })
    },

     // 发送邮箱验证码
    sendEmailCode() {
      let params = { 
        email: this.email,
        lang: 'zh'
      }
      this.$store.commit('load')
      email_code(params).then(res => {
        if(res) {
          this.getCode = true
          const timer = setInterval( () => {
            this.seconds --
            if(this.seconds < 0){
              this.getCode = false
              this.seconds = 60
              clearInterval(timer)
            }
          },1000)
        }
      }).finally ( () => {
        this.$store.commit('unload')
      })
    },

    onSend(){
      if(this.toggle) {
        this.sendPhoneCode()
      }else{
        this.sendEmailCode()
      }
    },

    // 验证手机验证码
    checkPhoneCode() {
      let params = {
        phone: this.phone,
        code: this.code
      }
      this.$store.commit('load')
      phone_code_check(params).then(res => {
        if(res) {
          this.$router.push('/zh/setting/password')
        }
      }).finally ( () => {
        this.$store.commit('unload')
      })
    },

    // 验证邮箱验证码
    checkEmailCode() {
      let params = {
        email: this.email,
        code: this.code
      }
      this.$store.commit('load')
      email_code_check(params).then(res => {
        if(res) {
          this.$router.push('/zh/setting/password')
        }
      }).finally ( () => {
        this.$store.commit('unload')
      })
    },

    onNext(){
      if(this.code == ''){
        this.$notify({ type: 'warning', message: '请先输入验证码' })
        return false
      }
      if(this.toggle) {
        this.checkPhoneCode()
      }else{
        this.checkEmailCode()
      }

    },


   
  },
  created(){
    this.phone = this.$route.query.phone
    this.email = this.$route.query.email
    if(this.phone != '' && this.email != '') {
      this.toggle = true
      this.all = true
    }else{
      this.all = false
      if(this.phone != '') {
        this.toggle = true
        this.placeholder = '请输入手机验证码' 
      }
      if(this.email != '') {
        this.toggle = false
        this.placeholder = '请输入邮箱验证码'
      }
      
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-identity {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding:0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    >input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    .get-code {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 5px 8px;
        border: 1px solid #3284FA;
        color: #3284FA;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
      }
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
  .for-email {
    margin-top: 30px;
    text-align: center;
    font-size:14px;
    font-weight:400;
    color:rgba(51,51,51,1);
    text-decoration: underline;
  }
}
</style>